.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.listMaxStyle {
  max-height: 300px;
  overflow: scroll;
  
}
.borderListMax{
  border-left: 0!important;
  border-right: 0!important;
  border-top: 0!important;
}
.linkStyleNone{
  text-decoration: none;
}
.ant-input {
  border-color: #ced4da;
  height: 38px;
}

.ant-input:focus {   
  /* border-color: rgba(126, 239, 104, 0.8); */
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px ;
  outline: 0 none;
}
.ant-btn  {
  border-color: #ced4da;
  height: 38px!important;
}
.ant-list-empty-text{
  display: none;
}
.ant-list-items {
  background-color: white;
  padding-left: 15px!important;
  padding-right: 15px!important;
  border-radius: 5px;
}
/* .google-maps-link{
  display: none!important;
} */

@media screen and (max-width: 767px) {
  .dropdown-menu {
    border: none!important;
  }
  }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.leaflet-container {
  background-color: #fff!important;
  height: 100vh;
}
.leaflet-containerInner {
  background-color: #fff!important;
  height: 350px;
  border-radius: 5px;
}
.fullMapContainer {
  height: 500px!important;
  background-color: #fff!important;
  border-radius: 5px;
}

.leaflet-popup-tip{
  display: none !important; 
}
.leaflet-popup-close-button{
  display: none !important; 
}
.leaflet-control-attribution{
  display: none;
}
/* .leaflet-popup-content {
  padding-bottom: 0%;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left:15px;
  margin-right: 20px;
} */
.leaflet-popup-content {
  padding-bottom: 0%!important;
  margin-bottom: 0px!important;
  margin-top: 0px!important;
  margin-left:0px!important;
  margin-right: 0px!important;
}
.leaflet-control-zoom-out{
  border: none!important;
}
.leaflet-bar{
  border: none!important;
}
.leaflet-bar a{
  /* line-height: 0px!important; */
  /* color: red!important; */
  font-size: 32px!important;
  border: none!important;
  /* line-break: none!important; */
}

.btn-link{
  /* color: #0d6efd; */
  color: black;
  text-decoration: none;
}
.btn-linkSpecial{
  margin: 0px;
  padding: 3px;
}
.buttonFatFont{
  font-size: 18px;
}
/* .hoveLinkButton{
  color: red!important
} */
/* .hoveLinkButton{
  color: rgba(0, 0, 0, 0.7)!important
} */
.hoveLinkButton:hover{
  color: #0d6efd;
}
.leaflet-popup-content-wrapper{
  margin: 0px!important;
  padding: 0px!important;
  border: 1px solid rgba(0, 0, 0, 0.3)!important;
  
}
.btnBgNone{
  background-color: white;
  border-color: white;
}
.btnActive{
  background-color: black!important;
  border-color: black!important;
  color: white!important;
}
.vr{
  font-size: 22px;
  /* width: 2px; */
  opacity: 0.3;
}
/* .rbt-menu {
  
  position: relative!important;
  z-index: 1000000000!important;
  overflow-y: auto
} */
.rbt {
  outline: none; 
  position: relative;
}
/* .typeahead.dropdown-menu {
  z-index: 200000!important;
  } */
  /* .rbt-menu.dropdown-menu {
    background-color: red;
    z-index: 1051000000000000!important;
    position:relative!important;
    top:0!important;
    left:0!important;
    
  } */
  .girlLookAtThatBody{
    overflow-y:inherit !important;
}
  
.leaflet-control-layers-toggle{ 
  /* content:"your text"; 
  background-color: red; */
  
  width: 30px!important;
  height: 30px!important;
  color: red!important;
  /* text-decoration: none; */
  background-image: url(data:image/svg+xml,%3Csvg%20stroke%3D%22currentColor%22%20fill%3D%22none%22%20stroke-width%3D%222%22%20viewBox%3D%220%200%2024%2024%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20height%3D%2222%22%20width%3D%2222%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20color%3D%22%230d6efd%22%20style%3D%22color%3A%20rgb%2813%2C%20110%2C%20253%29%3B%22%3E%3Cpolygon%20points%3D%2222%203%202%203%2010%2012.46%2010%2019%2014%2021%2014%2012.46%2022%203%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E)!important;

}
.leaflet-control-layers {
  border-color: #0d6efd!important;
  border-width: 1.3px!important;
  border-radius: 4px!important;
  margin-top: 4px!important;
}
/* .leaflet-control-layers-toggle{ 
  

} */
.leaflet-control .btn {
  /* background-color: red; */
  padding: 0px;
  width: 32px!important;
  height: 32px!important;
} 
.leaflet-control-layers-overlays label{
  /* background-color: red!important; */
  text-align: left;
}

.dropdown-toggle2::after {
  display: none !important; 
}
.dropdownMax{
  width: 300px;
}
.chart_div_gantt {
  overflow-x: scroll;
  overflow-y: scroll;

}
.accordion-button {
  padding-top: 10px;
  padding-bottom: 10px;
}
.borderRadius0{
  border-radius: 0px!important;
}
#viewer-container {
  position: fixed;
  top: 50px;
  /* left: 0; */
  /* right: 0; */
  /* padding: 0; */
  /* margin: 0; */
  /* outline: none; */
  width: 100%;
  height: 100%;
}
.positionAbsolute{
  position: fixed;
  /* top: 50; */
  /* left: 0; */
}
.btn-ssm{
  font-size: 10px;
}
/* .react-pdf__Page{
  width: 30% !important;
  height: 100% !important;
}
.react-pdf__Document{
  width: 30% !important;
  height: 100% !important;
}
.react-pdf__Page__canvas{
  margin: 0 auto;
  width: 30% !important;
  height: 100% !important;
} */