input[type=text]:disabled {
    border: none;
    background-color: white!important;
}
input[type=email]:disabled {
    border: none;
    background-color: white!important;
}
input[type=checkbox]:disabled {
    background-color: white!important;
    /* display: none; */
}
button:disabled {
    display: none;
}
/* a:disabled {
    display: none;
} */
div.disabled {
    background-color: red!important;
}
