#chat2 .form-control {
    border-color: transparent;
  }
  
  #chat2 .form-control:focus {
    border-color: transparent;
    box-shadow: inset 0px 0px 0px 1px transparent;
  }
  
  .divider:after,
  .divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
  }

  .ChatBox{
    overflow:scroll; 
    height:415px;
  }
  .ChatBox2{
    overflow:scroll; 
    height:350px;
  }

  .messageWrapper {
    /* background-color: red; */
    display: none!important;
    /* margin: 0%!important; */

  }

  .mouseFinger {
    cursor: pointer;
  }
  .divWrappMessage {
    font-size: 12px!important;
    /* color: red */
  }
  .rbt-token {
    background-color: #f8f9fa!important;
    color: #0d6efd!important;
    border-radius: 15px!important;
  }
  